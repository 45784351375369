import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import SideBar from '../../components/sidebar';
import LearnMore from '../../components/learnmore';
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor';

const AlreadyMedicare = ({ location }) => (
	<Layout location={location} heading1="Already on Medicare" heading2="Get peace of mind from UVM Health&nbsp;Advantage.">
    	<Seo title="Already on Medicare | Turning 65 | Medicare Basics" meta={[{description: 'If you are looking for a Medicare plan to better fit your lifestyle, or if you are looking to lower your costs, you can switch to another Medicare plan.'}]} bodyclass="medicare-basics section3 subpage" />
			
		<div class="constrained">
			<div class="content-constrained">
				<div class="flexwrap_subpage">

					<main>
						<h3>Switching to a UVM Health Advantage Medicare Plan</h3>
						
						<p>If you are looking for a Medicare plan to better fit your lifestyle, or if you are looking to lower your costs, you can switch to another Medicare plan. This is typically done during the open enrollment period of October 15 through December 7 each year. However, you can also make changes when certain qualifying events happen in your life, such as moving or losing your current insurance. <a href="https://www.medicare.gov/sign-up-change-plans/when-can-i-join-a-health-or-drug-plan/special-circumstances-special-enrollment-periods" class="external">Learn more about Special Enrollment Periods</a>.</p>
							
						<p><Link to="/plans/uvm-health-advantage/">Compare UVMHA Medicare Advantage plans.</Link></p>

						<p>When choosing a Medicare plan, it&rsquo;s important to compare costs&mdash;not only your monthly premiums, but the TOTAL COST of your coverage. This includes co-payments and other out-of-pocket charges for which you are responsible.</p>

						<h4>UVM Health Advantage Plan Guides are here to help</h4>
						<p>Our Plan Guides will listen to your needs and help you understand your options so that you can make a well-informed decision with no disruption to your care. You&rsquo;ll be asked questions such as:</p>
						<ul>
							<li key={1}>Who are your doctors and other health care providers? We want to verify that they&rsquo;re in our network.</li>
							<li key={2}>What medications are you taking? We want you to know what&rsquo;s covered and what the costs will be.</li>
							<li key={3}>What other health care needs or concerns do you have? We want to make sure that we&rsquo;ve got the benefits and support to meet those needs.</li>
						</ul>
						<p>Our goal is simple &ndash; no surprises and complete confidence! We want you to know exactly what you&rsquo;ll get with a UVM Health Advantage plan. And after you&rsquo;ve selected a plan, our Plan Guides will help ensure a smooth transition.</p>

						<LearnMore />
						<SpeakWithAdvisor />
					</main>

					<SideBar location={location} />
				</div>
			</div>
		</div>
	</Layout>
)

export default AlreadyMedicare
